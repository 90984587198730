import { getConfig } from "@src/config";
import { GQLNotFoundPageQuery } from "@src/graphql.gatsby.generated";
import { generateUrlFromFilter } from "@src/shared/context/searchfilter/generateUrlFromFilter";
import { parseFilterFromUrl } from "@src/shared/context/searchfilter/parseFilterFromUrl";
import NotFound from "@src/shared/notFound/NotFound";
import isLandingPage from "@src/shared/utils/isLandingPage";
import Master from "@src/skeleton/layout/Master";
import { graphql, navigate, PageProps } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import { useIntl } from "react-intl";

const NotFoundPage: React.FunctionComponent<PageProps<GQLNotFoundPageQuery>> = ({ location, data }) => {
    const intl = useIntl();
    const scope = getConfig("scope");

    if (isLandingPage(location)) {
        if (typeof window === "object") {
            const filter = parseFilterFromUrl(location, data.leaseme.allVehicles.facets);
            navigate(generateUrlFromFilter(location, filter, "vehicles", false), {
                replace: true,
            });
        }
        return null;
    }

    let title: string;

    if (scope.domain === "lease-me.porschebank.at") {
        title = intl.formatMessage({ id: "lease-me.404.htmlTitle", defaultMessage: "404" });
    } else {
        // Todo add right title for Car4me
        title = intl.formatMessage({ id: "car4me.404.htmlTitle", defaultMessage: "404" });
    }

    return (
        <>
            <Helmet title={title} />
            <Master marginTop={80}>
                <NotFound scope={scope} />
            </Master>
        </>
    );
};

export default NotFoundPage;

export const query = graphql`
    query NotFoundPage($leasemeScope: LeaseMe_ContentScopeInput!) {
        leaseme {
            allVehicles(scope: $leasemeScope) {
                facets {
                    ...AllFacets
                }
            }
        }
    }
`;
