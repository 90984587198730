import styled from "styled-components";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
    max-width: 430px;
    padding: 20px;
`;
export const NotFound404 = styled.span`
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    font-size: 100px;
    line-height: 100px;
    text-align: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        font-size: 130px;
        line-height: 130px;
    }
`;

export const NotFoundHeadline = styled.span`
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    ${({ theme }) => theme.breakpoints.md.mediaquery} {
        line-height: 50px;
        font-size: 40px;
    }
`;

export const NotFoundDescription = styled.span`
    color: ${({ theme }) => theme.colors.porscheBankBlue};

    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    margin-top: 40px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    min-height: 130px;

    ${({ theme }) => theme.breakpoints.sm.mediaquery} {
        flex-direction: row;
        min-height: 0;
    }
`;
