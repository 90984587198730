import { Link } from "gatsby";
import * as React from "react";
import { FormattedMessage } from "react-intl";

import Button, { ButtonVariant } from "../button/Button";
import * as sc from "./NotFound.sc";

interface Props {
    scope: {
        domain: string;
    };
}

const NotFound: React.FunctionComponent<Props> = ({ scope }) => (
    <sc.ContentWrapper>
        <sc.NotFound404>404</sc.NotFound404>
        <sc.NotFoundHeadline>
            <FormattedMessage id="leaseme.notFound" defaultMessage="Seite nicht gefunden" />
        </sc.NotFoundHeadline>
        <sc.NotFoundDescription>
            <FormattedMessage
                id="leaseme.notFound.description"
                defaultMessage="Die von Ihnen gesuchte Seite konnte nicht gefunden werden oder das
                Fahrzeug ist nicht mehr verfügbar."
            />
        </sc.NotFoundDescription>
        <sc.ButtonWrapper>
            <Link to="/">
                <Button variant={ButtonVariant.Gray}>
                    <FormattedMessage id="leaseme.notFound.backHome" defaultMessage="Zur Startseite" />
                </Button>
            </Link>
            <Link to={scope.domain !== "car4me.porschefinance.hu" ? "/leasing/" : "/openEndLeasing/"}>
                <Button variant={ButtonVariant.Primary}>
                    <FormattedMessage id="leaseme.notFound.allLeasingOffers" defaultMessage="Alle Leasing-Angebote" />
                </Button>
            </Link>
        </sc.ButtonWrapper>
    </sc.ContentWrapper>
);

export default NotFound;
